
















































































































































































































































































































































































































































































































































































































































































































































































































































































@import '~@/assets/styles/components/modal-cadastro';
.box-relogio {
  text-align: center;
  font-size: 20px;
  line-height: 1.1;

  span {
    font-size: 40px;
    font-weight: bold;
  }
}

.box-palpites {
  &-palpite {
    background-color: rgba(0,0,0,.09);
    border-top: 1px dashed rgba(0, 0, 0, .2);

    &:nth-child(2n) {
      background-color: rgba(0,0,0,.03);
    }

    &:first-child {
      margin-top: 10px !important;
    }

    &:last-child {
      border-bottom: 1px dashed rgba(0, 0, 0, .2);
    }
  }
}

.checkbox-loterias-disable {
  color:rgba(0, 0, 0, .2) !important;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
